import React from "react";
import { useStaticQuery, graphql } from 'gatsby';

import SocialLinks from "../../components/_molecules/sociallinks";
import EmailLink from "../../components/_atoms/emailLink";

const ContactSection = () => {
    const data = useStaticQuery(graphql`{
        contact: jsonJson(name: {eq: "contact"}) {
            social {
              link
              type
            }
            email {
              address
              label
              link
            }
          }
        }
    `);

    return (
        <div className="c-contactSection__root">
            <EmailLink {...data.contact.email} />
            <SocialLinks keyprefix="contact-section-" items={data.contact.social} />
        </div>
    );
};

export default ContactSection;

